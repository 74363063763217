import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Stars from "./Stars"
import Button from "@bit/azheng.joshua-tree.button"
import classnames from "classnames"

const AllSocialReviews = ({
  reviews,
  language,
  heading,
  buttonHref,
  buttonText,
  isColumn,
  colorBack,
  animation,
}) => {
  const animate = animation ? "fade-up" : ""

  const wrapperClasses = classnames({
    "home-section": heading,
    "ratings-review top-down": true,
    "color-back": colorBack,
  })

  const reviewsContainer = classnames({
    "all-reviews-container-flex": isColumn,
    "all-reviews-container-grid": !isColumn,
  })

  const data = useStaticQuery(graphql`
    query SocialQuery {
      googleJson {
        data {
          code
          platform
          platformId
          totalReviews
          rating
          url
        }
      }
      facebookJson {
        data {
          code
          platform
          platformId
          totalReviews
          rating
          url
        }
      }
    }
  `)

  let l1 = data.googleJson.data
  // let l2 = data.camasJson.data
  // let l3 = data.dallesJson.data
  // let l4 = data.milwaukieJson.data

  let facebook = data.facebookJson.data

  // GMB url overwrite
  l1.url =
    "https://www.google.com/search?q=huntsville+oral+surgery+2317+Whitesburg+Dr+Huntsville%2C+AL+35801&rlz=1C5CHFA_enUS910US910&oq=huntsville+oral+surgery+2317+Whitesburg+Dr+Huntsville%2C+AL+35801&aqs=chrome..69i57j46i175i199j0i22i30j0i22i30i457j0i22i30j69i60j69i61j69i60.4174j0j9&sourceid=chrome&ie=UTF-8#lrd=0x886213520971870f:0x1444005ad0a93d19,1"

  if (!reviews) {
    reviews = [
      { name: "", ...l1 },
      // {name: "", ...facebook},
    ]
  }

  return (
    <section className={wrapperClasses}>
      <div className="container" data-aos={animate} data-aos-duration="1200">
        {heading && (
          <div className="columns">
            <div className="column is-2"></div>
            <div className="column is-20">
              <h2 className="has-text-centered h3-style">{heading}</h2>
            </div>
            <div className="column is-2"></div>
          </div>
        )}
        <div
          className="rating-reviews home-reviews columns is-centered iconless big-rating reviews-width"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          {/* <div className="column is-2"></div> */}
          <div
            className={reviewsContainer}
            style={{ flexDirection: isColumn ? "column" : "row" }}
          >
            {reviews &&
              reviews.map((review, i) => (
                <div className="featured-social">
                  <div>
                    {!review.name && isColumn ? (
                      <></>
                    ) : (
                      <span
                        role="presentation"
                        className="reviews-city"
                        style={{
                          fontSize: "0.875rem",
                          lineHeight: "1.71",
                          color: "#6d6e6f",
                          display: "block",
                          marginBottom: review.name ? "-4px" : "20px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {review.name ? review.name + " Office" : ""}
                      </span>
                    )}
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      className="link-wrapper"
                      href={review.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h3>
                        {review.platform === "googleMyBusiness"
                          ? "Google"
                          : "Facebook"}
                      </h3>
                      <div className="total-reviews">
                        ({review.totalReviews})
                      </div>
                    </a>
                  </div>
                  <div>
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      href={review.url}
                      className="reviews-link"
                      target="_blank"
                    >
                      <div className="review-icons">
                        <Stars rating={review.rating} />
                      </div>
                    </a>
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      href={review.url}
                      className="reviews-link"
                      target="_blank"
                    >
                      <span className="reviews-number">
                        {review.rating === 5
                          ? "5.0"
                          : review.rating === 4
                          ? "4.0"
                          : review.rating}
                      </span>
                    </a>
                  </div>
                </div>
              ))}
          </div>
          {/* <div className="column is-2"></div> */}
        </div>
      </div>

      {buttonHref && (
        <div className="columns has-text-centered">
          <div className="column">
            <Button contained buttonText={buttonText} href={buttonHref} />
          </div>
        </div>
      )}
    </section>
  )
}

export default AllSocialReviews
